<template>
  <div>
    <!-- :selectable="option => ! option.value.includes('nothing_selected')" -->
    <!-- <v-select
      :id="id"
      v-model="localValue"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="countryOptions"
      label="countryName"
      :reduce="country => country.countryShortCode"
      :placeholder="placeholder"
      :class="className"
      :autocomplete="autocompleteAttr"
      @input="onChange"
    /> -->
    <!-- <v-autocomplete
      v-model="localValue"
      :items="countryOptions"
      :filter="customFilter"
      outlined
      :disabled="false"
      color="white"
      item-text="countryName"
      item-value="countryShortCode"
      :label="placeholder"
    ></v-autocomplete> -->
    <!-- :filter="customFilter" -->
    <v-autocomplete
      v-model="localValue"
      :items="countryOptions"
      outlined
      item-text="countryName"
      item-value="countryShortCode"
      :label="placeholder"
      hide-details="auto"
      :error-messages="errorMessage"
      :rules="rules"
      clearable
      @input="onChange"
    ></v-autocomplete>
  </div>
</template>

<script>

// { countryName: 'Afghanistan', countryShortCode: 'AF' }
// import vSelect from 'vue-select'
import regions from '@/services/master/country-region'

export default {
  name: 'CountrySelect',
  components: {
    // vSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    id: {
      type: String,
      default: 'countrySelect',
    },
    countryData: {
      type: String,
      default: '',
    },
    whiteList: Array,
    blackList: Array,
    className: String,
    autocomplete: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select Country',
    },
    usei18n: {
      type: Boolean,
      default: true,
    },

    // Use for change language after component was renderer
    countryLanguage: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countryOptions: this.getCountryOptions(),
      localValue: this.countryData,
    }
  },
  computed: {
    autocompleteAttr() {
      const autocompleteType = showsFullCountryName => (showsFullCountryName ? 'country-name' : 'country')

      return this.autocomplete ? autocompleteType(this.countryName) : 'nope' // off (no funciona con chrome)
    },
  },
  watch: {
    // Use for change language after component was renderer
    countryLanguage: {
      handler(val) {
        if (val) {
          this.countryOptions = this.getCountryOptions()
        }
      },
    },
  },
  methods: {
    // El evento envía el valor seleccionado
    // eslint-disable-next-line no-unused-vars
    onChange(value) {
      this.$emit('input', this.localValue)
    },

    // Country list using PROPS (Include translation using i18n)
    getCountryOptions() {
      let countryList = regions

      //   let countryList = regions.filter(region => region.countryShortCode !== this.countryData)
      if (this.whiteList) {
        countryList = countryList.filter(country => this.whiteList.includes(country.countryShortCode))
      }
      if (this.blackList) {
        countryList = countryList.filter(country => !this.blackList.includes(country.countryShortCode))
      }
      if (this.$i18n && this.usei18n) {
        countryList = countryList.map(country => {
          const localeCountry = { ...country }

          // localeCountry.countryName = this.$t(country.countryName)
          localeCountry.countryName = this.$t(`CountrySelect.${country.countryName}`)

          return localeCountry
        })
        countryList.sort((country1, country2) => ((country1.countryName > country2.countryName) ? 1 : -1))
      }

      return countryList
    },

    // En caso de que querramos indicar el nombre (No se usa)
    topCountryName(topCountry) {
      const regionObj = regions.find(region => region.countryShortCode === topCountry)
      if (!regionObj) {
        return ''
      }
      if (this.$i18n && this.usei18n) {
        return this.$t(`CountrySelect.${regionObj.countryName}`)
      }

      return this.shortCodeDropdown ? regionObj.countryShortCode : regionObj.countryName
    },
  },
}
</script>
